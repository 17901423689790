<template>
  <div v-if="isShow" class="box_da">
    <div class="content">
      <el-form
        label-width="120px"
        :model="chainData"
        ref="chainRef"
        :rules="chainRule"
      >
        <div class="mainTop">
          <el-form-item label="场景类型">
            <el-radio-group v-model="chainData.sceneType">
              <el-radio label="通用场景"></el-radio>
              <el-radio label="行业场景"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="场景名称："
            style="width: 470px"
            prop="sceneName"
          >
            <el-input v-model="chainData.sceneName"></el-input>
          </el-form-item>
          <el-form-item label="上级场景：" style="width: 470px" prop="">
            <el-select
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              v-model="chainData.superiorSceneId"
            >
              <el-option
                v-for="item in sceneList"
                :key="item.id"
                :label="item.sceneName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级行为：" style="width: 470px" prop="superiorBehaviorId">
            <el-select
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethods"
              v-model="chainData.superiorBehaviorId"
            >
              <el-option
                v-for="item in sceneArr"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景图片：">
            <UploadImage
              @upLoadImgList="lookClick"
              @upOldLoadImgList="saveClick"
              :upLoadImgList.sync="chainData.sceneImgUrl"
              :upOldLoadImgList.sync="chainData.sceneImgList"
            />
          </el-form-item>
          <el-form-item label="行业类别：">
            <!-- <el-cascader
              v-model="chainData.industryLabelList"
              :options="industryThreeOptions"
              :props="industryThreeProps"
              ref="industryThree"
              @change="handleContentChaChange"
              clearable
              collapse-tags
            ></el-cascader> -->
            <div v-for="(item, index) in industrylist" :key="index">
              <el-checkbox-group v-model="item.labelparam1">
                <!-- 显示 typelist -->
                <el-checkbox
                  v-for="o in item.typelist"
                  :label="o.id"
                  :key="o.id"
                  @change="industrycodechange1(o)"
                >
                  <!-- 锚点 -->
                  <span>{{ o.industryName }}</span>
                </el-checkbox>
              </el-checkbox-group>
              <!-- 显示 codechildlist1 -->
              <div class="child_label" v-if="item.codechildlist1.length > 0">
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >二级标签:</span
                >

                <el-checkbox-group v-model="item.labelparam2">
                  <el-checkbox
                    v-for="i in item.codechildlist1"
                    :label="i.id"
                    :key="i.id"
                    @change="industrycodechangechild(i)"
                    >{{ i.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                v-if="item.childlist.length > 0"
                class="child_label"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >三级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam3">
                  <el-checkbox
                    v-for="el in item.childlist"
                    :label="el.id"
                    :key="el.id"
                    @change="industrycodechangechil4(el)"
                    >{{ el.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childlist4.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >四级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam4">
                  <el-checkbox
                    v-for="els in item.childlist4"
                    :label="els.id"
                    :key="els.id"
                    @change="industrycodechangechil5(els)"
                    >{{ els.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode5.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >五级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam5">
                  <el-checkbox
                    v-for="item in item.childcode5"
                    :label="item.id"
                    :key="item.id"
                    @change="industryparamchange5(item)"
                    >{{ item.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode6.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >六级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam6">
                  <el-checkbox
                    v-for="item in item.childcode6"
                    :label="item.id"
                    :key="item.id"
                    @change="industryparamchange6(item)"
                    >{{ item.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="所属产业链：">
           <!-- <div class="industrialChain_flex">
            <el-radio-group
            
            v-model="chainData.industrialChain"
          >
         
            <el-radio
              v-for="(o, i) in industrialChainOptions"
              :label="o"
              :key="i"
            >
            
              <span>{{ o }}</span>
            </el-radio>
          </el-radio-group>
           </div> -->
           <el-select
              filterable
              clearable
              remote
              reserve-keyword
              placeholder="请输入所属产业链"
              :remote-method="selectAllIndustrialChainName"
              v-model="chainData.industrialChain"
            >
              <el-option
                v-for="(item,i) in industrialChainOptions"
                :key="i"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属产品：">
            <el-input v-model="chainData.affiliatedProducts"></el-input>
          </el-form-item>
          <el-form-item label="所属行为：">
            <el-checkbox-group v-model="chainData.enterpriseBehaviorLabels">
              <!-- 显示 typelist -->
              <el-checkbox
                v-for="(o, i) in behaviorOptions"
                :label="o.codeName"
                :key="i"
              >
                <!-- 锚点 -->
                <span>{{ o.codeName }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <!-- <el-select
              v-model="chainData.enterpriseBehaviorLabels"
              filterable
              placeholder="请选择"
              multiple
            >
              <el-option
                v-for="(item, index) in behaviorOptions"
                :key="index"
                :label="item.codeName"
                :value="item.codeName"
              >
              </el-option>
            </el-select> -->
            <!-- <el-cascader
              v-model="chainData.enterpriseBehaviorLabels"
              :options="behaviorOptions"
              :props="behaviorProps"
              clearable
              collapse-tags
            ></el-cascader> -->
          </el-form-item>
          <el-form-item label="所属生产工艺：" label-width="140px" style="width: 470px" prop="">
                <el-select
                  filterable
                  remote
                  multiple
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="getAllScenarioProcess"
                  v-model="chainData.productionProcessList"
                  @change="$forceUpdate()"
                >
                  <el-option
                    v-for="item in rocessList"
                    :key="item.id"
                    :label="item.processName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          <!-- <el-form-item label="所属生产工艺：">
            <div v-for="(item, index) in radiolist" :key="index">
              <el-checkbox-group v-model="item.labelparam1">
            
                <el-checkbox
                  v-for="o in item.typelist"
                  :label="o.id"
                  :key="o.id"
                  @change="codechange1(o)"
                >
             
                  <span>{{ o.industrialName }}</span>
                </el-checkbox>
              </el-checkbox-group>
       
              <div class="child_label" v-if="item.codechildlist1.length > 0">
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >二级标签:</span
                >

                <el-checkbox-group v-model="item.labelparam2">
                  <el-checkbox
                    v-for="i in item.codechildlist1"
                    :label="i.id"
                    :key="i.id"
                    @change="codechangechild(i)"
                    >{{ i.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                v-if="item.childlist.length > 0"
                class="child_label"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >三级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam3">
                  <el-checkbox
                    v-for="el in item.childlist"
                    :label="el.id"
                    :key="el.id"
                    @change="codechangechil4(el)"
                    >{{ el.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childlist4.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >四级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam4">
                  <el-checkbox
                    v-for="els in item.childlist4"
                    :label="els.id"
                    :key="els.id"
                    @change="codechangechil5(els)"
                    >{{ els.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode5.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >五级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam5">
                  <el-checkbox
                    v-for="item in item.childcode5"
                    :label="item.id"
                    :key="item.id"
                    @change="paramchange5(item)"
                    >{{ item.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode6.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >六级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam6">
                  <el-checkbox
                    v-for="item in item.childcode6"
                    :label="item.id"
                    :key="item.id"
                    @change="paramchange6(item)"
                    >{{ item.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="潜在痛点：" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList" :key="i">
              <el-input v-model="o.name"></el-input>
              <i @click="addpush(0)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(0, i)"
              ></i>
            </div>
          </el-form-item> -->
          <el-form-item label="潜在痛点：">
            <!-- <el-input v-model="chainData.potentialPainPoint"></el-input> -->
            <el-input
                   style="width: 50%"
                     maxlength="100"
                  show-word-limit
                  v-model="chainData.potentialPainPoint"
               
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
          </el-form-item>
          
          <!-- <el-form-item
            label="生产制造类别："
            v-if="
              isShowManufacturingCategory || chainData.manufacturingCategory
            "
          >
            <el-radio-group v-model="chainData.manufacturingCategory">
              <el-radio label="离散型制造业">离散型制造业</el-radio>
              <el-radio label="流程型制造业">流程型制造业</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="场景描述：">
            <!-- <el-input
              v-model="chainData.sceneDescribe"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input> -->
            <!-- <Editorbar
            :maxlength="100"
              v-model="chainData.sceneDescribe"
              :isClear="isClear"
             
              style="width: 50%"
            /> -->
            <el-input
                   style="width: 50%"
                     maxlength="100"
                  show-word-limit
                  v-model="chainData.sceneDescribe"
               
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
          </el-form-item>
          <el-form-item label="场景现状：">
            <!-- <el-input
              v-model="chainData.sceneSituation"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input> -->
            <Editorbar
              v-model="chainData.sceneSituation"
              :isClear="isClear"
              style="width: 50%"
            />
            <!-- <el-input
                  style="width: 470px;"
                     maxlength="100"
                  show-word-limit
                  v-model="chainData.sceneSituation"
               
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input> -->
          </el-form-item>

          <!-- <el-form-item label="技术方案：">
            <el-input
              v-model="chainData.technicalSolution"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="关联企业：">
            <el-input v-model="chainData.associatedCompanyName"></el-input>
          </el-form-item> -->
          <el-form-item label="关联企业：" style="width: 470px" prop="associatedCompanyId">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethodssss"
                  v-model="chainData.associatedCompanyId"
                  @change="clickCompany"
                >
                  <el-option
                    v-for="item in sceneArr2"
                    :key="item.id"
                    :label="item.companyFullName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联产品：" style="width: 470px" prop="">
                <el-select
                  filterable
                  multiple
                  remote
                    value-key="productCode"
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethodsss"
                  v-model="chainData.productResults"
                >
                  <el-option
                    v-for="item in sceneArr1"
                    :key="item.productCode"
                    :label="item.productName"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          <el-form-item label="相关链接：">
            <el-input v-model="chainData.associatedCompanyUrl"></el-input>
          </el-form-item>
          <div class="border_flex">
            <span class="border_left"></span>
            <span>收益</span>
          </div>
          <!-- <el-form-item label="收益：" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList1" :key="i">
              <el-input v-model="o.name"></el-input>
              <i @click="addpush(1)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(1, i)"
              ></i>
            </div>
          </el-form-item> -->
          <el-form-item label="短期收益：" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList2" :key="i">
              <el-input maxlength="25"  show-word-limit v-model="o.name"></el-input>
              <i @click="addpush(2)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(2, i)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="长期收益：" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList3" :key="i">
              <el-input maxlength="25"  show-word-limit v-model="o.name"></el-input>
              <i @click="addpush(3)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(3, i)"
              ></i>
            </div>
          </el-form-item>
          <div class="border_flex">
            <span class="border_left"></span>
            <span>风险</span>
          </div>
          <el-form-item label="管理风险" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList4" :key="i">
              <el-input maxlength="25"  show-word-limit  v-model="o.name"></el-input>
              <i @click="addpush(4)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(4, i)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="科技风险" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList5" :key="i">
              <el-input maxlength="25"  show-word-limit v-model="o.name"></el-input>
              <i @click="addpush(5)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(5, i)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="合规风险" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList6" :key="i">
              <el-input maxlength="25"  show-word-limit v-model="o.name"></el-input>
              <i @click="addpush(6)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(6, i)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="组织风险" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList7" :key="i">
              <el-input maxlength="25"  show-word-limit v-model="o.name"></el-input>
              <i @click="addpush(7)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(7, i)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="舆论风险" style="width: 640px" prop="">
            <div class="circle_flex" v-for="(o, i) in qianList8" :key="i">
              <el-input maxlength="25"  show-word-limit v-model="o.name"></el-input>
              <i @click="addpush(8)" class="el-icon-circle-plus-outline"></i>
              <i
                v-if="i != 0"
                class="el-icon-remove-outline"
                @click="remove(8, i)"
              ></i>
            </div>
          </el-form-item>
          <div class="border_flex">
            <span class="border_left"></span>
            <span>投入</span>
          </div>
          <el-form-item
            label="科技建设："
            style="width: 470px"
            prop="technologyConstruction"
          >
            <el-input maxlength="25"  show-word-limit v-model="chainData.technologyConstruction"></el-input>
          </el-form-item>
          <el-form-item
            label="运算资源："
            style="width: 470px"
            prop="computingResources"
          >
            <el-input maxlength="25"  show-word-limit v-model="chainData.computingResources"></el-input>
          </el-form-item>
          <el-form-item
            label="数据与知识："
            style="width: 470px"
            prop="dataKnowledge"
          >
            <el-input maxlength="25"  show-word-limit v-model="chainData.dataKnowledge"></el-input>
          </el-form-item>
          <el-form-item
            label="组织变革："
            style="width: 470px"
            prop="organizationalChange"
          >
            <el-input maxlength="25"  show-word-limit v-model="chainData.organizationalChange"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">创建人：{{ chainData.createName }}</div>
        <div class="ess_people_list">修改人：{{ chainData.updateName }}</div>
        <div class="ess_people_list">创建时间：{{ chainData.createTimes }}</div>
        <div class="ess_people_list">修改时间：{{ chainData.updateTimes }}</div>
      </div>
    </div>
    <!-- <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem" >取消</el-button>
    </div> -->
  </div>
</template>

<script>
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import UploadImage from "../Exercise/ActiveData/uploadImage";
import Editorbar from "@/components/Editorbar";
import {
  insertScenarioBasic,
  selectById,
  updateScenarioBasic,
  getDicRadioButton,
  selectAllIndustrialChainName,
  getAllIndustrialChainName,
  getIndustryThree,
} from "@/api/Sceneknowledge.js";
import {
  selectAssociatedScenarios,
  selectAssociated,
  getProductByName,
  getZcByCompanyName,
  getAllScenarioProcess
} from "@/api/standardKnowledge.js";
export default {
  name: "App",
  components: {
    DicCheckboxGroup,
    UploadImage,
    Editorbar,
  },
  watch: {
    "chainData.id": {
      handler(newVal) {
        if (this.chainData && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getDicRadioButton().then(() => {
      this.selectAllIndustrialChainName().then(() => {
        this.getIndustryThree().then(() => {
          setTimeout(() => {
            if (this.personType) {
              this.search().then(() => {
                // this.selectIndustrialChain();
              });
            } else if (!this.personType) {
              this.isShow = true;
            }
          }, 500);
        });
      });
    });
  },
  computed: {
    personData() {
      return Number(this.$route.query.id);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  data() {
    return {
      sceneList: [], //
      sceneArr: [],
      sceneArr1:[],
      sceneArr2:[],
      isClear: null,
      isShow: false,
      isShowManufacturingCategory: false,
      options: [],
      chainData: {
        sceneName: "", //场景名称
        sceneProperties: "通用场景", //场景属性
        // sceneDescribe: "", //场景描述
        // industryCategoriesList: [], //行业类别
        supplyChainIndustryChild: [],
        sceneImgList: [], //场景图片
        sceneImgUrl: [],
        associatedScenarios: "", //关联场景
        enterpriseBehaviorLabels: [], // 企业行为
        industrialChain: "", //产业链
        industrialChainLabelList: [], //产业链标签
        manufacturingCategory: "", //生产制造类别
        sceneDescribe: "", //生产制造类别
        sceneSituation: "", //生产制造类别
        technicalSolution: "", //生产制造类别
        industryLabelList: [],
        longTermReturnsList: [], //长期收益
        manageRisksList: [], //管理风险
        organizationalRiskList: [], //组织风险
        potentialPainPointList: [], //潜在痛点
        publicOpinionRiskList: [], //舆论风险
        complianceRiskList: [], //
        shortTermGainsList: [], //短期收益
        technologicalRisksList: [], //技术风险
        incomeList: [], //收益
        productResults:[]
      },
      rocessList:[],
      //潜在痛点
      qianList: [
        {
          name: "",
        },
      ],
      //收益
      qianList1: [
        {
          name: "",
        },
      ],
      //短期收益
      qianList2: [
        {
          name: "",
        },
      ],
      //长期收益
      qianList3: [
        {
          name: "",
        },
      ],
      //管理风险
      qianList4: [
        {
          name: "",
        },
      ],
      //科技风险
      qianList5: [
        {
          name: "",
        },
      ],
      //合规风险
      qianList6: [
        {
          name: "",
        },
      ],
      //组织风险
      qianList7: [
        {
          name: "",
        },
      ],
      //舆论风险
      qianList8: [
        {
          name: "",
        },
      ],
      radiolist: [
        {
          typelist: [], //一级标签
          codechildlist1: [],
          childlist: [],
          childlist4: [],
          childcode5: [],
          childcode6: [],
          labelparam1: [],
          labelparam2: [],
          labelparam3: [],
          labelparam4: [],
          labelparam5: [],
          labelparam6: [],
        },
      ],
      //企业行为
      behaviorOptions: [],
      behaviorProps: {
        multiple: true,
        value: "codeName",
        label: "codeName",
        children: "childNode",
      },
      // 产业链行为
      industrialChainOptions: [],
      industrialChainProps: { value: "codeId", label: "codeName" },
      //产业链标签
      industryChainLabelOptions: [],
      industryChainLabelProps: {
        multiple: true,
        value: "id",
        label: "industrialName",
      },
      //行业标签
      industryThreeOptions: [],
      industrylist: [],
      industryThreeProps: {
        multiple: true,
        value: "id",
        label: "industryName",
        children: "childNode",
      },
      chainRule: {
        sceneName: [
          { required: true, message: "请填写场景名称", trigger: "blur" },
        ],
        sceneProperties: [
          { required: true, message: "请选择场景属性", trigger: "blur" },
        ],
        associatedCompanyId: [
          { required: true, message: "请选择企业", trigger: "blur" },
        ],
        superiorBehaviorId:[
          { required: true, message: "请选择上级行为", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    addpush(index) {
      if (index == 0) {
        this.qianList.push({
          name: "",
        });
      } else if (index == 1) {
        this.qianList1.push({
          name: "",
        });
      } else if (index == 2) {
        this.qianList2.push({
          name: "",
        });
      } else if (index == 3) {
        this.qianList3.push({
          name: "",
        });
      } else if (index == 4) {
        this.qianList4.push({
          name: "",
        });
      } else if (index == 5) {
        this.qianList5.push({
          name: "",
        });
      } else if (index == 6) {
        this.qianList6.push({
          name: "",
        });
      } else if (index == 7) {
        this.qianList7.push({
          name: "",
        });
      } else if (index == 8) {
        this.qianList8.push({
          name: "",
        });
      }
    },
    remove(index, i) {
      if (index == 0) {
        this.qianList.splice(i, 1);
      } else if (index == 1) {
        this.qianList1.splice(i, 1);
      } else if (index == 2) {
        this.qianList2.splice(i, 1);
      } else if (index == 3) {
        this.qianList3.splice(i, 1);
      } else if (index == 4) {
        this.qianList4.splice(i, 1);
      } else if (index == 5) {
        this.qianList5.splice(i, 1);
      } else if (index == 6) {
        this.qianList6.splice(i, 1);
      } else if (index == 7) {
        this.qianList7.splice(i, 1);
      } else if (index == 8) {
        this.qianList8.splice(i, 1);
      }
    },
    lookClick(val) {
      this.chainData.sceneImgUrl = val;
    },
    saveClick(val) {
      this.chainData.sceneImgList = val;
    },
    async remoteMethod(query) {
      const res = await selectAssociatedScenarios({ sceneName: query });
      if (res.code == 200) {
        this.sceneList = res.data;
      }
    },
    async remoteMethods(query) {
      const res = await selectAssociated({ sceneName: query });
      if (res.code == 200) {
        this.sceneArr = res.data;
      }
    },
    async remoteMethodsss(query) {
        
        const res = await getProductByName({ productName: query });
        if (res.code == 200) {
          this.sceneArr1 = res.data;
        }
      },
      async remoteMethodssss(query) {
        
        const res = await getZcByCompanyName({ companyFullName: query });
        if (res.code == 200) {
          this.sceneArr2 = res.data;
        }
      },
    async  getAllScenarioProcess(query){
         
        const res = await getAllScenarioProcess({ labelName: query });
        if (res.code == 200) {
          this.rocessList = res.data;
        }
      },
      clickCompany(val){
        this.sceneArr2.forEach(el=>{
          if(val==el.id){
            this.chainData.associatedCompanyName=el.companyFullName
          }
        })
      },
    // 获取企业行为
    async getDicRadioButton() {
      const res = await getDicRadioButton({ code: "046" });
      if (res.code == 200) {
        this.behaviorOptions = res.data;
      }
    },
    // 获取产业链
    async selectAllIndustrialChainName(query) {
      const res = await selectAllIndustrialChainName({name:query});
      if (res.code == 200) {
        this.industrialChainOptions = res.data;
      }
    },
    // 获取产业链标签
    async selectIndustrialChain() {
      this.chainData.industrialChainLabelList = [];
      const res = await getAllIndustrialChainName({
        supplyChainName: this.chainData.industrialChain,
      });
      if (res.code == 200) {
        this.industryChainLabelOptions = res.data.mapTree;
        this.radiolist[0].typelist = this.industryChainLabelOptions;
        // console.log(this.chainData.industrialChainLabelList);
        // this.radiolist[0].labelparam1 = this.chainData.industrialChainLabelList.filter((s) => this.industryChainLabelOptions.some((ele) => ele.id == s));
        // console.log(this.radiolist[0].labelparam1 );
        // this.radiolist = [
        //   {
        //     typelist: this.industryChainLabelOptions, //一级标签
        //     codechildlist1: [],
        //     childlist: [],
        //     childlist4: [],
        //     childcode5: [],
        //     childcode6: [],
        //     labelparam1: [],
        //     labelparam2: [],
        //     labelparam3: [],
        //     labelparam4: [],
        //     labelparam5: [],
        //     labelparam6: [],
        //   },
        // ];
      }

      // this.echolabel()
    },
    codechange1(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].codechildlist1 =
            this.radiolist[0].codechildlist1.concat(o.children);
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].codechildlist1 =
          this.radiolist[0].codechildlist1.filter(
            (s) =>
              !o.children.some((ele) => ele.industrialName == s.industrialName)
          );
        this.radiolist[0].labelparam1 = this.radiolist[0].labelparam1.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    codechangechild(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childlist = this.radiolist[0].childlist.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childlist = this.radiolist[0].childlist.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam2 = this.radiolist[0].labelparam2.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    codechangechil4(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childlist4 = this.radiolist[0].childlist4.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childlist4 = this.radiolist[0].childlist4.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam3 = this.radiolist[0].labelparam3.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    codechangechil5(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childcode5 = this.radiolist[0].childcode5.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childcode5 = this.radiolist[0].childcode5.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam4 = this.radiolist[0].labelparam4.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    paramchange5(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childcode6 = this.radiolist[0].childcode6.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childcode6 = this.radiolist[0].childcode6.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam5 = this.radiolist[0].labelparam5.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    industrycodechange1(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].codechildlist1 =
            this.industrylist[0].codechildlist1.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].codechildlist1 =
          this.industrylist[0].codechildlist1.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam1 =
          this.industrylist[0].labelparam1.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industrycodechangechild(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childlist =
            this.industrylist[0].childlist.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childlist = this.industrylist[0].childlist.filter(
          (s) => !o.childNode.some((ele) => ele.industryName == s.industryName)
        );
        this.industrylist[0].labelparam2 =
          this.industrylist[0].labelparam2.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industrycodechangechil4(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childlist4 =
            this.industrylist[0].childlist4.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childlist4 =
          this.industrylist[0].childlist4.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam3 =
          this.industrylist[0].labelparam3.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industrycodechangechil5(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childcode5 =
            this.industrylist[0].childcode5.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childcode5 =
          this.industrylist[0].childcode5.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam4 =
          this.industrylist[0].labelparam4.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industryparamchange5(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childcode6 =
            this.industrylist[0].childcode6.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childcode6 =
          this.industrylist[0].childcode6.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam5 =
          this.industrylist[0].labelparam5.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    async selectIndustrialChains() {
      const res = await getAllIndustrialChainName({
        supplyChainName: this.chainData.industrialChain,
      });
      if (res.code == 200) {
        this.industryChainLabelOptions = res.data.mapTree;

        this.radiolist[0].typelist = this.industryChainLabelOptions;

        this.radiolist[0].labelparam1 =
          this.chainData.industrialChainLabelList.filter((s) =>
            this.industryChainLabelOptions.some((ele) => ele.id == s)
          );

        this.industryChainLabelOptions.forEach((o, i) => {
          this.radiolist[0].labelparam1.forEach((el, index) => {
            if (o.id == el) {
              if (o.children) {
                this.radiolist[0].codechildlist1 =
                  this.radiolist[0].codechildlist1.concat(o.children);

                this.radiolist[0].labelparam2 =
                  this.chainData.industrialChainLabelList.filter((s) =>
                    this.radiolist[0].codechildlist1.some((ele) => ele.id == s)
                  );
                this.radiolist[0].codechildlist1.forEach((a) => {
                  this.radiolist[0].labelparam2.forEach((c) => {
                    if (a.id == c) {
                      if (a.children) {
                        this.radiolist[0].childlist =
                          this.radiolist[0].childlist.concat(a.children);
                        this.radiolist[0].labelparam3 =
                          this.chainData.industrialChainLabelList.filter((s) =>
                            this.radiolist[0].childlist.some(
                              (ele) => ele.id == s
                            )
                          );

                        this.radiolist[0].childlist.forEach((s) => {
                          this.radiolist[0].labelparam3.forEach((p) => {
                            if (s.id == p) {
                              if (s.children) {
                                this.radiolist[0].childlist4 =
                                  this.radiolist[0].childlist4.concat(
                                    s.children
                                  );
                                this.radiolist[0].labelparam4 =
                                  this.chainData.industrialChainLabelList.filter(
                                    (o) =>
                                      this.radiolist[0].childlist4.some(
                                        (ele) => ele.id == o
                                      )
                                  );
                                this.radiolist[0].childlist4.forEach((u) => {
                                  this.radiolist[0].labelparam4.forEach((y) => {
                                    if (u.id == y) {
                                      if (u.children) {
                                        this.radiolist[0].childcode5 =
                                          this.radiolist[0].childcode5.concat(
                                            u.children
                                          );
                                        this.radiolist[0].labelparam5 =
                                          this.chainData.industrialChainLabelList.filter(
                                            (o) =>
                                              this.radiolist[0].childcode5.some(
                                                (ele) => ele.id == o
                                              )
                                          );
                                        this.radiolist[0].childcode5.forEach(
                                          (t) => {
                                            this.radiolist[0].labelparam5.forEach(
                                              (n) => {
                                                if (t.id == n) {
                                                  if (t.children) {
                                                    this.radiolist[0].childcode6 =
                                                      this.radiolist[0].childcode6.concat(
                                                        t.children
                                                      );
                                                    this.radiolist[0].labelparam6 =
                                                      this.chainData.industrialChainLabelList.filter(
                                                        (o) =>
                                                          this.radiolist[0].childcode6.some(
                                                            (ele) => ele.id == o
                                                          )
                                                      );
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        );
                                      }
                                    }
                                  });
                                });
                              }
                            }
                          });
                        });
                      }
                    }
                  });
                });
              }
            }
          });
        });
      }
    },
    //获取行业标签
    async getIndustryThree() {
      const res = await getIndustryThree();
      if (res.code == 200) {
        this.industryThreeOptions = res.data;
        this.industrylist = [
          {
            typelist: this.industryThreeOptions, //一级标签
            codechildlist1: [],
            childlist: [],
            childlist4: [],
            childcode5: [],
            childcode6: [],
            labelparam1: [],
            labelparam2: [],
            labelparam3: [],
            labelparam4: [],
            labelparam5: [],
            labelparam6: [],
          },
        ];
      }
    },
    handleContentChaChange() {
      const data = this.$refs["industryThree"].getCheckedNodes();
      // console.log(data);
      const isShow = data.find((item) => item.path[0] === 154);
      // this.chainData.manufacturingCategory = "离散型制造业";
      if (isShow) {
        this.isShowManufacturingCategory = true;
      } else {
        this.isShowManufacturingCategory = false;
        this.chainData.manufacturingCategory = "";
      }
    },

    async search() {
      const res = await selectById({ id: this.personData });
      if (res.code == 200) {
        // Object.assign(this.chainData, res.data);
        this.chainData = res.data;
        if(this.chainData.scenarioProcessResultList){
          this.chainData.productionProcessList=this.chainData.scenarioProcessResultList.map((el)=>{
          return el.id
        })
      
        
        this.getAllScenarioProcess('')
        }
       if(this.chainData.superiorBehaviorId){
        this.chainData.superiorBehaviorId=Number(this.chainData.superiorBehaviorId)
      
       }
        if(!this.chainData.productResults){
          this.chainData.productResults=[]
        }
        this.remoteMethod(this.chainData.superiorSceneName);
        this.remoteMethods(this.chainData.superiorBehaviorName)
        this.remoteMethodssss(this.chainData. associatedCompanyName)
        this.remoteMethodsss('')
        // if (this.chainData.potentialPainPointList) {
        //   this.qianList = this.chainData.potentialPainPointList.map((item) => {
        //     item = {
        //       name: item,
        //     };
        //     return item;
        //   }); //潜在痛点
        // }
        if(this.chainData.incomeList){
          this.qianList1 = this.chainData.incomeList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //收益
        }
        if(this.chainData.shortTermGainsList){
          this.qianList2 = this.chainData.shortTermGainsList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //短期收益
        }
        if(this.chainData.longTermReturnsList){
          this.qianList3 = this.chainData.longTermReturnsList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //长期收益
        }
        if(this.chainData.manageRisksList){
          this.qianList4 = this.chainData.manageRisksList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //管理风险
        }
       if(this.chainData.technologicalRisksList){
        this.qianList5 = this.chainData.technologicalRisksList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //技术风险
       }
        if(this.chainData.complianceRiskList){
          this.qianList6 = this.chainData.complianceRiskList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //合规风险
        }
        if(this.chainData.organizationalRiskList){
          this.qianList7 = this.chainData.organizationalRiskList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //组织风险
        }
        if(this.chainData.publicOpinionRiskList){
          this.qianList8 = this.chainData.publicOpinionRiskList.map((item) => {
          item = {
            name: item,
          };
          return item;
        }); //组织风险
        }
      
        // this.chainData.incomeList=this.qianList1.map(el=>{return el.name})//收益
        // this.chainData.shortTermGainsList=this.qianList2.map(el=>{return el.name})//短期收益
        // this.chainData.longTermReturnsList=this.qianList3.map(el=>{return el.name})//长期收益
        // this.chainData.manageRisksList=this.qianList4.map(el=>{return el.name})//管理风险
        // this.chainData.technologicalRisksList=this.qianList5.map(el=>{return el.name})//技术风险
        // this.chainData.complianceRiskList=this.qianList6.map(el=>{return el.name})//合规风险
        // this.chainData.organizationalRiskList=this.qianList7.map(el=>{return el.name})//组织风险
        // this.chainData.publicOpinionRiskList=this.qianList8.map(el=>{return el.name})//舆论风险

        if (!this.chainData.industryLabelList) {
          this.chainData.industryLabelList = [];
        } else {
          let list = [];
          let arr = [];
          this.chainData.industryLabelList.forEach((el, index) => {
            if (el.indexOf(",", 0) != -1) {
              arr = this.chainData.industryLabelList[index].split(",");
              arr.forEach((o, i) => {
                list.push(Number(o));
              });
            } else {
              list.push(Number(el));
            }
          });
          if (list.length) {
            this.chainData.industryLabelList = Array.from(new Set(list));
          }

          this.industrylist[0].labelparam1 =
            this.chainData.industryLabelList.filter((s) =>
              this.industryThreeOptions.some((ele) => ele.id == s)
            );
          // this.industryThreeOptions.forEach((o, i) => {
          //   this.industrylist[0].labelparam1.forEach((el, index) => {
          //     if (o.id == el) {
          //       if (o.childNode) {
          //         this.industrylist[0].codechildlist1 =
          //           this.industrylist[0].codechildlist1.concat(o.childNode);
          //         this.industrylist[0].labelparam2 =
          //           this.chainData.industryLabelList.filter((s) =>
          //             o.childNode.some((ele) => ele.id == s)
          //           );
          //         o.childNode.forEach((a) => {
          //           this.industrylist[0].labelparam2.forEach((c) => {
          //             if (a.id == c) {
          //               if (a.childNode) {
          //                 this.industrylist[0].childlist =
          //                   this.industrylist[0].childlist.concat(a.childNode);
          //                 this.industrylist[0].labelparam3 =
          //                   this.chainData.industryLabelList.filter((s) =>
          //                     a.childNode.some((ele) => ele.id == s)
          //                   );
          //                 a.childNode.forEach((s) => {
          //                   this.industrylist[0].labelparam3.forEach((p) => {
          //                     if (s.id == p) {
          //                       if (s.childNode) {
          //                         this.industrylist[0].childlist4 =
          //                           this.industrylist[0].childlist4.concat(
          //                             s.childNode
          //                           );
          //                         this.industrylist[0].labelparam4 =
          //                           this.chainData.industryLabelList.filter(
          //                             (o) =>
          //                               s.childNode.some((ele) => ele.id == o)
          //                           );
          //                       }
          //                     }
          //                   });
          //                 });
          //               }
          //             }
          //           });
          //         });
          //       }
          //     }
          //   });
          // });
          this.industryThreeOptions.forEach((o, i) => {
            this.industrylist[0].labelparam1.forEach((el, index) => {
              if (o.id == el) {
                if (o.childNode) {
                  this.industrylist[0].codechildlist1 =
                    this.industrylist[0].codechildlist1.concat(o.childNode);

                  this.industrylist[0].labelparam2 =
                    this.chainData.industryLabelList.filter((s) =>
                      this.industrylist[0].codechildlist1.some(
                        (ele) => ele.id == s
                      )
                    );
                  this.industrylist[0].codechildlist1.forEach((a) => {
                    this.industrylist[0].labelparam2.forEach((c) => {
                      if (a.id == c) {
                        if (a.childNode) {
                          this.industrylist[0].childlist =
                            this.industrylist[0].childlist.concat(a.childNode);
                          this.industrylist[0].labelparam3 =
                            this.chainData.industryLabelList.filter((s) =>
                              this.industrylist[0].childlist.some(
                                (ele) => ele.id == s
                              )
                            );

                          this.industrylist[0].childlist.forEach((s) => {
                            this.industrylist[0].labelparam3.forEach((p) => {
                              if (s.id == p) {
                                if (s.childNode) {
                                  this.industrylist[0].childlist4 =
                                    this.industrylist[0].childlist4.concat(
                                      s.childNode
                                    );
                                  this.industrylist[0].labelparam4 =
                                    this.chainData.industryLabelList.filter(
                                      (o) =>
                                        this.industrylist[0].childlist4.some(
                                          (ele) => ele.id == o
                                        )
                                    );
                                  this.industrylist[0].childlist4.forEach(
                                    (u) => {
                                      this.industrylist[0].labelparam4.forEach(
                                        (y) => {
                                          if (u.id == y) {
                                            if (u.childNode) {
                                              this.industrylist[0].childcode5 =
                                                this.industrylist[0].childcode5.concat(
                                                  u.childNode
                                                );
                                              this.industrylist[0].labelparam5 =
                                                this.chainData.industryLabelList.filter(
                                                  (o) =>
                                                    this.industrylist[0].childcode5.some(
                                                      (ele) => ele.id == o
                                                    )
                                                );
                                              this.industrylist[0].childcode5.forEach(
                                                (t) => {
                                                  this.industrylist[0].labelparam5.forEach(
                                                    (n) => {
                                                      if (t.id == n) {
                                                        if (t.childNode) {
                                                          this.industrylist[0].childcode6 =
                                                            this.industrylist[0].childcode6.concat(
                                                              t.childNode
                                                            );
                                                          this.industrylist[0].labelparam6 =
                                                            this.chainData.industryLabelList.filter(
                                                              (o) =>
                                                                this.industrylist[0].childcode6.some(
                                                                  (ele) =>
                                                                    ele.id == o
                                                                )
                                                            );
                                                        }
                                                      }
                                                    }
                                                  );
                                                }
                                              );
                                            }
                                          }
                                        }
                                      );
                                    }
                                  );
                                }
                              }
                            });
                          });
                        }
                      }
                    });
                  });
                }
              }
            });
          });
        }
        if (!this.chainData.industrialChainLabelList) {
          this.chainData.industrialChainLabelList = [];
        } else {
          let list = [];
          let arr = [];
          this.chainData.industrialChainLabelList.forEach((el, index) => {
            if (el.indexOf(",", 0) != -1) {
              arr = this.chainData.industrialChainLabelList[index].split(",");
              arr.forEach((o, i) => {
                list.push(Number(o));
              });
            } else {
              list.push(Number(el));
            }
          });
          if (list.length) {
            this.chainData.industrialChainLabelList = Array.from(new Set(list));
           
          }
          this.selectIndustrialChains();
        }
        if (this.chainData.updateTime) {
          this.chainData.updateTimes = this.timestampToTime(
            this.chainData.updateTime
          );
        }
        if (this.chainData.createTime) {
          this.chainData.createTimes = this.timestampToTime(
            this.chainData.createTime
          );
        }
        if (!this.chainData.enterpriseBehaviorLabels) {
          this.chainData.enterpriseBehaviorLabels = [];
        }
        // if (!this.chainData.industrialChainLabelList) {
        //   this.chainData.industrialChainLabelList = [];
        // } else {
        //   this.chainData.industrialChainLabelList = this.chainData.industrialChainLabelList.map(
        //     (item, index) => {
        //       return item.split(",");
        //     }
        //   );
        // }
        // if (!this.chainData.industryLabelList) {
        //   this.chainData.industryLabelList = [];
        // } else {
        //   this.chainData.industryLabelList = this.chainData.industryLabelList.map(
        //     (item, index) => {
        //       return item.split(",");
        //     }
        //   );
        // }
        if (!this.chainData.temporaryProductImg) {
          this.chainData.sceneImgList = [];
        } else {
          this.chainData.sceneImgUrl = this.chainData.temporaryProductImg;
        }
        if (!this.chainData.temporaryProductImg) {
          this.chainData.sceneImgUrl = [];
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    saveItem() {
      // console.log(this.radiolist);

      Object.assign(this.chainData, {
        Authorization: this.Authorization,
      });
      let res;
      this.$refs.chainRef.validate(async (valid) => {
        //async
        if (valid) {
          this.chainData.industryLabelList = [
            ...this.industrylist[0].labelparam1,
            ...this.industrylist[0].labelparam2,
            ...this.industrylist[0].labelparam3,
            ...this.industrylist[0].labelparam4,
            ...this.industrylist[0].labelparam5,
            ...this.industrylist[0].labelparam6,
          ];

          this.chainData.industrialChainLabelList = [
            ...this.radiolist[0].labelparam1,
            ...this.radiolist[0].labelparam2,
            ...this.radiolist[0].labelparam3,
            ...this.radiolist[0].labelparam4,
            ...this.radiolist[0].labelparam5,
            ...this.radiolist[0].labelparam6,
          ];

         
          // this.chainData.potentialPainPointList = this.qianList.map((el) => {
          //   return el.name;
          // }); //潜在痛点
          this.chainData.incomeList = this.qianList1.map((el) => {
            return el.name;
          }); //收益
          this.chainData.shortTermGainsList = this.qianList2.map((el) => {
            return el.name;
          }); //短期收益
          this.chainData.longTermReturnsList = this.qianList3.map((el) => {
            return el.name;
          }); //长期收益
          this.chainData.manageRisksList = this.qianList4.map((el) => {
            return el.name;
          }); //管理风险
          this.chainData.technologicalRisksList = this.qianList5.map((el) => {
            return el.name;
          }); //技术风险
          this.chainData.complianceRiskList = this.qianList6.map((el) => {
            return el.name;
          }); //合规风险
          this.chainData.organizationalRiskList = this.qianList7.map((el) => {
            return el.name;
          }); //组织风险
          this.chainData.publicOpinionRiskList = this.qianList8.map((el) => {
            return el.name;
          }); //舆论风险

          if (!this.personType) {
            res = await insertScenarioBasic(this.chainData);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            res = await updateScenarioBasic(this.chainData);
            if (res.code === 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            }
          }
        }
      });
    },
    cancelItem() {
      this.$router.go(-1);
    },
    handleClose(tag) {
      const index = this.chainData.fatherIds.indexOf(tag);
      this.chainData.fatherIds.splice(index, 1);
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

      var Y = date.getFullYear() + "-";

      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";

      var D = date.getDate();

      var h = date.getHours();

      var m = date.getMinutes();

      var s = date.getSeconds();

      return (
        Y +
        M +
        this.add0(D) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(m) +
        ":" +
        this.add0(s)
      ); //时分秒可以根据自己的需求加上
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "行业":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "技术":
          this.chainData.supplyChainIndustryChild = val;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px 20px 0 20px;
  // margin-bottom: 20px;
}
.circle_flex {
  width: 680px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.content {
  overflow: auto;
 
  width: 100%;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}

.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
.border_flex{
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 5px 0px;
}
.border_left{
  width: 3px;
  height: 20px;
  background: #1e9fff;
  display: inline-block;
  margin-right: 5px;
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
// /deep/.el-textarea {
//   width: 80% !important;
//   // min-height: 40px !important;
// }
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.bottomStyle {
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
  /deep/ .el-upload--picture-card {
    width: 150px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 150px;
    height: 100px;
    // line-height: 150px;
  }
  /deep/ .el-upload {
    background-color: #fff;
  }
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.flexStyle {
  display: flex;
  margin: 10px 10px 10px 0;
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-tag.el-tag--info {
  background: rgba(30, 159, 255, 0.2);
  color: rgb(30, 159, 255);
}
/deep/ .el-tag.el-tag--info .el-tag__close {
  color: rgb(30, 159, 255);
  background: rgba(30, 159, 255, 0.2);
}
</style>
